import { Box, Button, Typography } from "@material-ui/core";
import { TFunction } from "next-i18next";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import useStyles from "../../../styles/components/DialogAuth";
import theme from "../../theme";

type ModalOtpProps = {
  readonly t: TFunction;
  handleAuth: (otp: string) => void;
  handleResendOtp: () => void;
  authData: {
    name: string;
    phoneNumber: string;
    email: string;
  };
  verifyMethod: string;
  error: string;
};

const ModalOTP = (props: ModalOtpProps) => {
  const { t, handleAuth, handleResendOtp, authData, verifyMethod, error } =
    props;
  const classes = useStyles();
  const [inputOtp, setInputOtp] = useState("");
  const [second, setSecond] = useState(60);
  const [enableResend, setEnableResend] = useState(false);

  const handleInputOtp = (otp) => {
    setInputOtp(otp);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!enableResend) {
        setSecond(second - 1);
        if (second <= 1) {
          setEnableResend(true);
        }
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [second]);

  const renderVerifyMethod = () => {
    if (verifyMethod === "phone-wa") {
      return "WhatsApp";
    }
    if (verifyMethod === "phone-sms") {
      return "SMS";
    }
    if (verifyMethod === "email") {
      return "Email";
    }
  };

  return (
    <>
      <Typography className={classes.dialogSubtitle}>
        {t("otp-sent-to")}{" "}
        <span className={classes.phoneNumber}>+{authData.phoneNumber}</span>{" "}
        {t("via")} {renderVerifyMethod()}
      </Typography>
      <OtpInput
        value={inputOtp}
        onChange={handleInputOtp}
        numInputs={6}
        isInputNum={true}
        containerStyle={{
          justifyContent: "center",
          gap: 8,
        }}
        inputStyle={{
          width: "100%",
          height: "55px",
          fontSize: 16,
          borderRadius: 16,
          padding: 5,
          border: error ? "1px solid #EF1B43" : "1px solid #989898",
        }}
      />
      {error && (
        <Typography className={classes.errorMessageOtp} variant="body2">
          {t("error-otp")}
        </Typography>
      )}
      <Box mt="24px" mb="32px">
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.loginButton}
          onClick={() => handleAuth(inputOtp)}
        >
          {t("verify")}
        </Button>
      </Box>
      <Box textAlign="center">
        <Typography variant="body2">
          {t("not-receive-code")}{" "}
          <span
            className={classes.underlineButton}
            onClick={() => {
              if (enableResend) {
                handleResendOtp();
                setEnableResend(false);
                setSecond(60);
              }
            }}
          >
            {enableResend
              ? `${t("send-again")}`
              : `${t("wait")} ` + second + ` ${t("second")}`}
          </span>
        </Typography>
      </Box>
    </>
  );
};

export default ModalOTP;
